import { Link } from "react-router-dom";
import TermsCondition from "../../components/termsCondition/TermsConditions";

function TermsConditionsPage() {
  return (
    <>
      {/* <section className="page_tittle">
        <div className="common_banner_container">
          <div className="content_box">
            <div className="content_wrapper">
              <div className="title">
                <h1>Terms & Conditions</h1>
              </div>
              <ul className="bread_crumb clearfix">
                <li className="breadcrumb_item">
                  <Link to="/home">Home &nbsp;</Link>
                </li>
                <li className="breadcrumb_item">|</li>
                <li className="breadcrumb_item">Terms & Conditions</li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      <TermsCondition />
    </>
  );
}
export default TermsConditionsPage;
