import { Link } from "react-router-dom";
import Contact from "../../components/contact/Contact";
import GoogleMap from "../../components/googleMap/GoogleMap";
import CommonBanner from "../../components/common/commonBannner/CommonBanner";
import ContactBanner from "../../assets/images/commonBanner/contact-cover.png";

function ContactPage() {
  const commonData = {
    commonHeader: "Gallery",
    backLink: "Home",
    ownPage: "Gallery",
    image: ContactBanner,
  };
  return (
    <>
      <CommonBanner commonData={commonData} />
      <Contact />
      <GoogleMap />
    </>
  );
}
export default ContactPage;
