import React from "react";
import { useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { ModalTitle } from "react-bootstrap";

import { BiSolidDonateBlood } from "react-icons/bi";
import { FaBars } from "react-icons/fa6";

// import logo from "../../../../assets/images/logo/logo.png";
// import "./UperHeader.css";
// import phonepay from "../../../../assets/images/phonePay/PhonePe_Logo.png";
import axios from "axios";

function PhonePayPaymentComp() {
  const [inputval, setInputval] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    adhaarNumber: "",
    panNo: "",
    city: "",
    state: "",
    zip: "",
    amount: "",
  });
  const params = useParams();
  console.log("params----", params);

  const [urlData, setUrlData] = useState();
  const [showButton, setShowButton] = useState(false);
  const [hideButton, setHideButton] = useState(true);
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const onChangeHandler = (e) => {
    const inpName = e.target.name;
    const inpVal = e.target.value;
    const cloned = { ...inputval };
    cloned[inpName] = inpVal;
    setInputval(cloned);
  };

  const handleSubmit = async (event) => {
    // event.preventdefault()
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowButton(false);
    } else {
      event.preventDefault();
      try {
        setLoading(true);
        const resp = await axios.post(
          `https://onlineparttimejobs.in/api/paymentgate/add_phonepetrans`,
          inputval
        );
        setUrlData(resp.data?.url);
        setLoading(false);
        setShowButton(true);
        setHideButton(false);
      } catch (error) {
        setLoading(false);
        alert("Server Error");
        setShowButton(false);
      }
    }
    setValidated(true);
  };
  return (
    <>
      <div className="auto_container">
        <div className="inner_container">
          <div className="container">
            <div
              className="phonePayFormWrapper"
              style={{ padding: "20px 80px" }}
            >
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-2">
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="firstname"
                      placeholder="First name"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="lastname"
                      placeholder="Last name"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group as={Col} md="6" controlId="validationCustom03">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      required
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid email.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom03">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="number"
                      name="phone"
                      placeholder="Phone Number"
                      required
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Phone Number.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group as={Col} controlId="validationCustom03">
                    <Form.Label>Adhar Number</Form.Label>
                    <Form.Control
                      type="number"
                      name="adhaarNumber"
                      placeholder="Adhar Number"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Adhar Number.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group as={Col} controlId="validationCustom03">
                    <Form.Label>Pan No</Form.Label>
                    <Form.Control
                      type="text"
                      name="panNo"
                      placeholder="PAN Number"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid city.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <ModalTitle className="mb-2 mt-3">address:-</ModalTitle>
                <Row className="mb-2">
                  <Form.Group as={Col} md="6" controlId="validationCustom03">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      placeholder="City"
                      required
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid city.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom04">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      name="state"
                      placeholder="State"
                      required
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid state.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom05">
                    <Form.Label>Zip</Form.Label>
                    <Form.Control
                      type="text"
                      name="zip"
                      placeholder="Zip"
                      required
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid zip.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Form.Group className="mb-4">
                  <Form.Check
                    required
                    label="Agree to terms and conditions"
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                </Form.Group>
                <Row className="mb-2">
                  <Form.Group as={Col} controlId="validationCustomUsername">
                    <Form.Label>Donation Amount </Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        Donate Total
                      </InputGroup.Text>
                      <Form.Control
                        type="Number"
                        name="amount"
                        placeholder="Donate Amount"
                        aria-describedby="inputGroupPrepend"
                        required
                        onChange={onChangeHandler}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please choose a username.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>
                {hideButton && (
                  <Row className="mt-4 mb-4">
                    {loading ? (
                      <h4
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          color: "gray",
                        }}
                      >
                        Processing...
                      </h4>
                    ) : (
                      <Button type="submit" variant="success">
                        Donate Now
                      </Button>
                    )}
                  </Row>
                )}

                {showButton && (
                  <Row className="mt-3 mb-5">
                    <Link
                      to={urlData}
                      variant="success"
                      style={{
                        width: "30%",
                        margin: "auto",
                        textDecoration: "none",
                        backgroundColor: "#0A3981",
                        color: "white",
                        padding: "8px",
                        textAlign: "center",
                        borderRadius: "3px",
                      }}
                    >
                      Proceed
                    </Link>
                  </Row>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PhonePayPaymentComp;
