import call from "../../../assets/images/footer/telephone-call.png";
import mail from "../../../assets/images/footer/envelope.png";
import location from "../../../assets/images/footer/location.png";

import footerlogo from "../../../assets/images/logo/footerlogo.png";
import socialiconsfa from "../../../assets/images/footer/facebook.png";
import socialiconstw from "../../../assets/images/footer/twitter.png";
import socialiconsin from "../../../assets/images/footer/instagram.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="footer_sec">
        <div className="footer-auto-container">
          <div className="footer_main_container">
            <div className="container">
              {" "}
              <div className="row">
                <div className="col-lg-3 col-md-3">
                  <div className="footer_logo">
                      {/* <img src={logo} alt="" className="logo_img" /> */}
                      <Link to={"/"}>
                        Education & Economic Development Society
                      </Link>
                  </div>
                  <div className="ova_social mt-4">
                    <ul className="social_list">
                      <li className="social_item">
                        <a href="">
                          <img
                            src={socialiconsfa}
                            alt=""
                            className="social_icon"
                          />
                        </a>
                      </li>
                      <li className="social_item">
                        <a href="">
                          <img
                            src={socialiconstw}
                            alt=""
                            className="social_icon"
                          />
                        </a>
                      </li>
                      <li className="social_item">
                        <a href="">
                          <img
                            src={socialiconsin}
                            alt=""
                            className="social_icon"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="ova_links">
                    <h4>Quick Links</h4>
                    <ul className="ova_quick_link">
                      <li className="ova_quick_links_list">
                        <Link to="/home" className="footer_quick_links">
                          home
                        </Link>
                      </li>
                      <li className="ova_quick_links_list">
                        <Link to="/aboutUs" className="footer_quick_links">
                          about
                        </Link>
                      </li>
                      <li className="ova_quick_links_list">
                        <Link to="/contact" className="footer_quick_links">
                          contact
                        </Link>
                      </li>
                      <li className="ova_quick_links_list">
                        <Link to="/gallery" className="footer_quick_links">
                          gallery
                        </Link>
                      </li>
                      <li className="ova_quick_links_list">
                        <Link to="" className="footer_quick_links">
                          blog
                        </Link>
                      </li>
                      <li className="ova_quick_links_list">
                        <Link
                          to="/terms-&-conditoins"
                          className="footer_quick_links"
                        >
                          term & conditions
                        </Link>
                      </li>
                      <li className="ova_quick_links_list">
                        <Link
                          to="/terms-&-conditoins"
                          className="footer_quick_links"
                        >
                          Vision Mission
                        </Link>
                      </li>
                      <li className="ova_quick_links_list">
                        <Link
                          to="/terms-&-conditoins"
                          className="footer_quick_links"
                        >
                          Our Services
                        </Link>
                      </li>
                      <li className="ova_quick_links_list">
                        <Link
                          to="/terms-&-conditoins"
                          className="footer_quick_links"
                        >
                          Our Volunteers
                        </Link>
                      </li>
                      <li className="ova_quick_links_list">
                        <Link
                          to="/terms-&-conditoins"
                          className="footer_quick_links"
                        >
                          Disclaimer
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5">
                  <div className="ova_links">
                    <h4>Contact Info</h4>
                    <div className="ova_contact_info_container mt-4">
                      {/* ---first--address--info */}
                      <div className="ova_contact_info mt-2">
                        <div className="contact_icon me-3">
                          <img
                            src={call}
                            alt=""
                            className="footer_third_icon"
                          />
                        </div>
                        <div className="contact_address">
                          <span>+91 8920721905</span>
                          <span>+91 9599934786</span>
                        </div>
                      </div>

                      {/* ---second----address--info */}

                      <div className="ova_contact_info mt-2">
                        <div className="contact_icon me-3">
                          <img
                            src={mail}
                            alt=""
                            className="footer_third_icon"
                          />
                        </div>
                        <div className="contact_address">
                          <span>eeds.society@gmail.com</span>
                        </div>
                      </div>

                      {/* ---third--address---info */}
                      <div className="ova_contact_info mt-2">
                        <div className="contact_icon me-3">
                          <img
                            src={location}
                            alt=""
                            className="footer_third_icon"
                          />
                        </div>
                        <div className="contact_address">
                          <span>
                            677/28B, Ashok Vatika, Devli Village, Near Sunder
                            Lal Hospital, New Delhi - 110080
                          </span>
                          {/* <span>info@chiragfoundation.in</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright_sec">
          <p>
            Copyright ©2022 Education & Economic Development Society | All Right
            Reserved | Powered By
            <a href="https://www.abarissoftech.com/" className="company">
              {" "}
              Abaris Softech Pvt Ltd
            </a>
          </p>
        </div>
      </footer>
    </>
  );
}
export default Footer;
