import { useState } from "react";

import { FaMapMarkerAlt } from "react-icons/fa";
import { FaSquarePhone } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
function Contact() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <>
      <section className="contact_sec">
        <div className="container">
          <div className="form_sec">
            <div className="row">
              <div className="col-lg-8">
                <div className="form_main_sec">
                  <div className="form_heading">
                    <h2 className="form_header">Get In Touch</h2>
                  </div>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                    className="form_body"
                  >
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="4"
                        lg="6"
                        controlId="validationCustom01"
                      >
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="First name"
                          id="contact-input"
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        lg="6"
                        controlId="validationCustomUsername"
                      >
                        <Form.Label>Email address</Form.Label>
                        <InputGroup hasValidation>
                          {/* <InputGroup.Text id="inputGroupPrepend">
                            @
                          </InputGroup.Text> */}
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            aria-describedby="inputGroupPrepend"
                            required
                            id="contact-input"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please choose a username.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="6"
                        lg="6"
                        controlId="validationCustom03"
                      >
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Phone number"
                          required
                          id="contact-input"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid number.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        lg="6"
                        controlId="validationCustom05"
                      >
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Subject"
                          required
                          id="contact-input"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid zip.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-5 mt-5">
                      <FloatingLabel
                        controlId="floatingTextarea2"
                        label="Comments"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Leave a comment here"
                          style={{ height: "150px" }}
                        />
                      </FloatingLabel>
                    </Row>

                    <Button type="submit" variant="success">
                      Send Message
                    </Button>
                  </Form>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contact_details_box">
                  <div className="contact_ova_heading mb-2">
                    <h1>Contact Info</h1>
                  </div>
                  <div className="contact_ova_address mb-2">
                    <span className="map_icon">
                      <FaMapMarkerAlt />
                    </span>
                    <span className="map_text_address">
                      <a href="" className="map_text">
                        Address:
                      </a>
                    </span>

                    <div className="map_address_details">
                      <p>
                      677/28B, Ashok Vatika, Devli Village, Near Sunder Lal Hospital, New Delhi - 110080
                      </p>
                    </div>
                  </div>

                  <div className="contact_ova_address mb-2">
                    <span className="map_icon">
                      <FaSquarePhone />
                    </span>
                    <span className="map_text_address">
                      <a href="" className="map_text">
                        Phone No:
                      </a>
                    </span>

                    <div className="map_address_details">
                      <p>+91 8920721905
                      </p>
                      <p>+91 9599934786</p>
                    </div>
                  </div>

                  <div className="contact_ova_address mb-4">
                    <span className="map_icon">
                      <TfiEmail />
                    </span>
                    <span className="map_text_address">
                      <a href="" className="map_text">
                        Email:
                      </a>
                    </span>

                    <div className="map_address_details">
                      <p>
                      eeds.society@gmail.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Contact;
