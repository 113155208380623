import message from "../../../../assets/images/topheader/message.png";
import phonecall from "../../../../assets/images/topheader/phone-call.png";

import "./TopHeader.css";
function TopHeader() {
  return (
    <div className="topheader_container">
      <div className="container">
        <div className="topSec">
        <span>Email: eeds.society@gmail.com</span>
        <span>Phone: +91 8920721905, 9599934786</span>
        </div>
        
      </div>
    </div>
  );
}
export default TopHeader;
