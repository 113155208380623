import { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { ModalTitle } from "react-bootstrap";

import { BiSolidDonateBlood } from "react-icons/bi";
import { FaBars } from "react-icons/fa6";

import logo from "../../../../assets/images/logo/logo.png";
import "./UperHeader.css";
import phonepay from "../../../../assets/images/phonePay/PhonePe_Logo.png";
import axios from "axios";
// import pay from "../../../../assets/images/phonePay/pay.png"

function UperHeader() {
  const [inputval, setInputval] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    adhaarNumber: "",
    panNo: "",
    city: "",
    state: "",
    zip: "",
    amount: "",
  });
  const [urlData, setUrlData] = useState();
  const [showButton, setShowButton] = useState(false);
  const [hideButton, setHideButton] = useState(true);

  const [show, setShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const onChangeHandler = (e) => {
    const inpName = e.target.name;
    const inpVal = e.target.value;
    const cloned = { ...inputval };
    cloned[inpName] = inpVal;
    setInputval(cloned);
  };

  // const sendPayload = async () => {
  //   const resp = await axios.post(`https://onlineparttimejobs.in/api/paymentgate/add_phonepetrans`, inputval);
  //   setUrlData(resp.data?.url)
  //   setShowButton(true)
  //   navigate(resp.data?.url)
  //   console.log('resp--', resp)
  // }

  const handleSubmit = async (event) => {
    // event.preventdefault()
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowButton(false);
    } else {
      event.preventDefault();
      try {
        const resp = await axios.post(
          `https://onlineparttimejobs.in/api/paymentgate/add_phonepetrans`,
          inputval
        );
        setUrlData(resp.data?.url);
        setShowButton(true);
        setHideButton(false);
      } catch (error) {
        alert("Server Error");
        setShowButton(false);
      }
    }
    setValidated(true);
  };

  // console.log('urlData--', urlData)
  return (
    <>
      {/* <WebView
        source={{ uri: 'htttps//google.com' }}
        originWhitelist={['*']}
        onLoad={() => console.log('Loaded')}
        onError={() => console.error('An error has occurred')}
        onHttpError={() => console.error('An HTTP error has occurred')}
        onMessage={(msg) => console.log('Got a message', msg)}
        javaScriptEnabled={true}
        allowFileAccess={true}
        injectedJavaScript={injectedJS}
      /> */}
      {/* <iframe src="https//google.com"></iframe> */}

      <div className="header_uper">
        <div className="auto_container">
          <div className="inner_container">
            <div className="container">
              <div className="upper_header_row">
                <div className="col-lg-4 col-md-6 col-sm-2">
                  <div className="logo_box">
                    {/* <img src={logo} alt="" className="logo_img" /> */}
                    <Link to={"/"}>
                      Education & Economic <span>Development Society</span>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-8 col-md-6 col-sm-10 col-navbar">
                  <nav className="navbar_list_container">
                    <ul className="navlink_list">
                      <li className="nav_link_item">
                        <Link to="" className="page_link">
                          home
                        </Link>
                      </li>
                      <li className="nav_link_item">
                        <Link to="/aboutUs" className="page_link">
                          about
                        </Link>
                      </li>
                      <li className="nav_link_item">
                        <Link to="/gallery" className="page_link">
                          gallery
                        </Link>
                      </li>
                      <li className="nav_link_item">
                        <Link to="/" className="page_link">
                          Vision
                        </Link>
                      </li>
                      <li className="nav_link_item">
                        <Link to="/" className="page_link">
                          Mission
                        </Link>
                      </li>
                      <li className="nav_link_item">
                        <Link to="/video" className="page_link">
                          Videos
                        </Link>
                      </li>
                      <li className="nav_link_item">
                        <Link to="/contact" className="page_link">
                          contact
                        </Link>
                      </li>
                    </ul>
                  </nav>

                  <div
                    className="payment_btn_donate"
                    // onClick={() => setLgShow(true)}
                  >
                    <Link to={"/home/phonePay/:payment"}>
                      <div className="payment_button_content">
                        <div className="me-1">
                          {/* <img src={pay} alt="" /> */}
                          <BiSolidDonateBlood className="donate_icon" />
                        </div>
                        <div>
                          {/* <span className="payment_button_text">Donate Now</span> */}
                          <span className="payment_button_text">
                            Donate Now
                          </span>
                          {/* <div className="payment_button_securedby">
                            Secured By PhonePay
                          </div> */}
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="fabar-container">
                    <FaBars className="fabar-icon" onClick={handleShow} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose} className="ofcanvas_our">
        <Offcanvas.Header closeButton className="btn-close-btn">
          <Offcanvas.Title>
            <img src={logo} alt="" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mobile-aside-link">
            <ul className="mobile-aside-link-list">
              <li className="mobile-aside-link-item">
                <NavLink to="/home" className="mobile-aside-link-link">
                  Home
                </NavLink>
              </li>
              <li className="mobile-aside-link-item">
                <NavLink to="/aboutUs" className="mobile-aside-link-link">
                  About us
                </NavLink>
              </li>
              <li className="mobile-aside-link-item">
                <NavLink to="contact" className="mobile-aside-link-link">
                  Contact Us
                </NavLink>
              </li>
              <li className="mobile-aside-link-item">
                <NavLink to="/gallery" className="mobile-aside-link-link">
                  Gallery
                </NavLink>
              </li>
              <li className="mobile-aside-link-item">
                <NavLink to="/video" className="mobile-aside-link-link">
                  Video
                </NavLink>
              </li>
            </ul>
          </div>
        </Offcanvas.Body>

        <div className="social-links social-link-aside">
          <ul className="clearfix">
            <li>
              <NavLink href="https://www.facebook.com/">
                <span className="fab fa-facebook" />
              </NavLink>
            </li>
            <li>
              <NavLink href="https://www.linkedin.com/">
                <span className="fab fa-linkedin" />
              </NavLink>
            </li>
            <li>
              <NavLink href="https://www.skype.com/">
                <span className="fab fa-skype" />
              </NavLink>
            </li>
            <li>
              <NavLink href="https://www.twitter.com/">
                <span className="fab fa-twitter" />
              </NavLink>
            </li>
          </ul>
        </div>
      </Offcanvas>

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="pb-4"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Payment Mathod
          </Modal.Title>
          <img src={phonepay} alt="" className="phone_pay" />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="Offline"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="Phone Pay"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Form>
          </Row>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-2">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="firstname"
                  placeholder="First name"
                  onChange={onChangeHandler}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="lastname"
                  placeholder="Last name"
                  onChange={onChangeHandler}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group as={Col} md="6" controlId="validationCustom03">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  required
                  onChange={onChangeHandler}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom03">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  required
                  onChange={onChangeHandler}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid Phone Number.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group as={Col} controlId="validationCustom03">
                <Form.Label>Adhar Number</Form.Label>
                <Form.Control
                  type="number"
                  name="adhaarNumber"
                  placeholder="Adhar Number"
                  required
                  onChange={onChangeHandler}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid Adhar Number.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group as={Col} controlId="validationCustom03">
                <Form.Label>Pan No</Form.Label>
                <Form.Control
                  type="text"
                  name="panNo"
                  placeholder="PAN Number"
                  required
                  onChange={onChangeHandler}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid city.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <ModalTitle className="mb-2 mt-3">address:-</ModalTitle>
            <Row className="mb-2">
              <Form.Group as={Col} md="6" controlId="validationCustom03">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="City"
                  required
                  onChange={onChangeHandler}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid city.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  name="state"
                  placeholder="State"
                  required
                  onChange={onChangeHandler}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid state.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom05">
                <Form.Label>Zip</Form.Label>
                <Form.Control
                  type="text"
                  name="zip"
                  placeholder="Zip"
                  required
                  onChange={onChangeHandler}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid zip.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Form.Group className="mb-4">
              <Form.Check
                required
                label="Agree to terms and conditions"
                feedback="You must agree before submitting."
                feedbackType="invalid"
              />
            </Form.Group>
            <Row className="mb-2">
              <Form.Group as={Col} controlId="validationCustomUsername">
                <Form.Label>Donation Amount </Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    Donate Total
                  </InputGroup.Text>
                  <Form.Control
                    type="Number"
                    name="amount"
                    placeholder="Donate Amount"
                    aria-describedby="inputGroupPrepend"
                    required
                    onChange={onChangeHandler}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please choose a username.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
            {hideButton && (
              <Row className="mt-4 mb-4">
                <Button type="submit" variant="success">
                  Donate Now
                </Button>
              </Row>
            )}

            {showButton && (
              <Row className="mt-3 mb-5">
                <Link
                  to={urlData}
                  variant="success"
                  style={{
                    width: "30%",
                    margin: "auto",
                    textDecoration: "none",
                    backgroundColor: "#0A3981",
                    color: "white",
                    padding: "8px",
                    textAlign: "center",
                    borderRadius: "3px",
                  }}
                >
                  Proceed
                </Link>
              </Row>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default UperHeader;
