import { Link } from "react-router-dom";

import './CommonBanner.css'
function CommonBanner({ commonData }) {
  console.log(commonData);
  return (
    <>
      <section className="page_tittle" style={{backgroundImage:`url(${commonData.image})`}}>
        
        <div className="common_banner_container">
          <div className="container">
            <div className="content_wrapper">
              <div className="title">
                <h1>{commonData.commonHeader}</h1>
              </div>
              <ul className="bread_crumb clearfix">
                <li className="breadcrumb_item">
                  <Link to="/home">{commonData.backLink} &nbsp;</Link>
                </li>
                <li className="breadcrumb_item">|</li>
                <li className="breadcrumb_item">{commonData.ownPage}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default CommonBanner;
