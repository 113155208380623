import { FaCalendarPlus, FaUsers } from "react-icons/fa6";
import { FaBookReader, FaSearch } from "react-icons/fa";

import cancerPasent from "../../../assets/images/cancer-charity-award/doctor-pasent.jpg";
function CancerNgo() {
  return (
    <>
      <section className="cancer_ngo_sec">
        <div className="cancer_ngo_container">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="cancer_ngo_details_box">
                  
                  <p>
                  <strong>Purpose</strong> To create a safe and enabling environment where disabled people lead healthy life.
                  </p>
                  <p>
                  <strong>Aims/Objectives/Mission</strong> Our mission is to enable the underprivileged and disabled people to live independent and dignified life.
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="cancer_ngo_img_box">
                  <img src={cancerPasent} alt="" className="cancer_ngo_img" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cancer_ngo_facility_section">
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 20"
            preserveAspectRatio="none"
            className="shape_fill"
          >
            <path
              class="elementor-shape-fill"
              d="M519.8,0.2c-11,0-19.8,8.5-19.8,19c0-10.4-8.8-19-19.8-19L0,0v20h1000V0.2H519.8z"
            ></path>
          </svg> */}
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="health-box_wrapper box_wrap_border">
                  <div className="health_box">
                    <div className="health_icon_box">
                      <FaCalendarPlus className="focus_facility_icon" />
                    </div>
                    <div className="health_icon_box_content">
                      <h3>Health</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className="health-box_wrapper box_wrap_border">
                  <div className="health_box">
                    <div className="health_icon_box">
                      <FaBookReader className="focus_facility_icon" />
                    </div>
                    <div className="health_icon_box_content">
                      <h3>Education</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="health-box_wrapper box_wrap_border">
                  <div className="health_box">
                    <div className="health_icon_box">
                      <FaUsers className="focus_facility_icon" />
                    </div>
                    <div className="health_icon_box_content">
                      <h3>Child Care</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="health-box_wrapper">
                  <div className="health_box">
                    <div className="health_icon_box">
                      <FaSearch className="focus_facility_icon" />
                    </div>
                    <div className="health_icon_box_content">
                      <h3>Awareness</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default CancerNgo;
