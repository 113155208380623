import ReactPlayer from "react-player";

function VideoGallery() {
  return (
    <>
      <section className="mt-5 mb-5">
        <div className="gallery_container">
          <div className="container mb-5">
            <div className="row mt-2 mb-2">
              <div className="col-lg-4">
                <div className="video_container">
                  <ReactPlayer
                    url="https://youtu.be/eIlsNZqCQ8o?si=uMZf53aIVESBRR6L"
                    className="chirag_video img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="gallery_details_sec">
                  <p>
                    Education & Economic Development Society Distribution Food
                    Needy Person In This Slum Pandemic Period. Providing Skill
                    Development Training
                    <br />
                    <br />
                    (For Women’s And Adolescents Like Tailoring, Computer
                    Literacy, Handicraft Making And Self Defence Training).
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="video_container">
                  <ReactPlayer
                    url="https://youtu.be/eUwe0A3F2Ac?si=h9MHAoIQOBH--V8m"
                    className="chirag_video img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="gallery_video_box">
            <div className="container">
              <div className="row mt-2 mb-2">
                <div className="col-lg-4">
                  <div className="video_container">
                    <ReactPlayer
                      url="https://youtu.be/Se2XTlP35Fs?si=2kYaG-9uk1QgLWSq"
                      className="chirag_video img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="gallery_details_sec">
                    <p>
                      Education & Economic Development Society Helps Needy
                      People By Providing Food, Drinking Water, Education,
                      Needed Medicine, Medical Kit, Sanitary, And Many Types Of
                      Skill Developing Training For Womens. Thats Skills Always
                      Helps For Women Getting Jobs And Good Earning
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="video_container">
                    <ReactPlayer
                      url="https://youtu.be/FlGh4zjyXrU?si=s5KLfDeFQGJhhSzE"
                      className="chirag_video img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="gallery_video_box mt-5">
            <div className="cancer_video_sec bg-white">
              <div className="container ">
                <div className="row ">
                  <div className="col">
                    <div className="cancer_help_text">
                      <p>
                        “Cancer is just a chapter in our lives and not the whole
                        story. Every day you wake up is another opportunity to
                        be a blessing to someone else. Today is enough. Life
                        becomes more purposeful when you’re doing something
                        good.”
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="video_container">
                      <ReactPlayer
                        url="https://youtu.be/aImmugr4KG0?si=gKyt4cJWnWgD7Hdu"
                        className="chirag_video img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default VideoGallery;
