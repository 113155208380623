import { Navigate, Route, Routes } from "react-router-dom";

import "./assets/css/slick.css";
import "./assets/css/footer.css";
import "./assets/css/responsive.css";
import "./assets/css/custom.css";
import "./assets/css/style.css"

import Header from "./components/common/header/Header";
import HomePage from "./pages/home";
import Footer from "./components/common/footer/Footer";
import AboutUsPage from "./pages/aboutUs";
import GalleryPage from "./pages/gallery";
import ContactPage from "./pages/contact";
import TermsConditionsPage from "./pages/termsConditions";
import BlogPage from "./pages/blog";
import VideoGalleryPage from "./pages/videoGallery";
import PhonePayPaymentFormPage from "./pages/phonePayPaymentFormPage";

function App() {
  return (
    <div className="App">
      <Header />

      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/aboutUs" element={<AboutUsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/video" element={<VideoGalleryPage />} />

        <Route path="/terms-&-conditoins" element={<TermsConditionsPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/home/phonePay/:payment" element={<PhonePayPaymentFormPage />} />
        <Route path="/home/phonePay-payment" element={<PhonePayPaymentFormPage />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
