import commonBannerVideo from "../../assets/images/commonBanner/video-cover.jpg";
import CommonBanner from "../../components/common/commonBannner/CommonBanner";
import VideoGallery from "../../components/videoGallery/VideoGallery";

function VideoGalleryPage() {
  const commonData = {
    commonHeader: "Video",
    backLink: "Home",
    ownPage: "Video",
    image: commonBannerVideo,
  };
  return (
    <>
      <CommonBanner commonData={commonData} />
      <VideoGallery />
    </>
  );
}
export default VideoGalleryPage;
