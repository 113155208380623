import fram1 from "../../../assets/images/banner/chirag-got award1.jpg";
import fram2 from "../../../assets/images/banner/chirag-got award2.jpg";
import fram3 from "../../../assets/images/banner/chirag-got award3.jpg";
import fram4 from "../../../assets/images/banner/chirag-got award4.jpg";
import fram5 from "../../../assets/images/banner/chirag-got award5.jpg";
import fram6 from "../../../assets/images/banner/chirag-got award6.jpg";
import fram7 from "../../../assets/images/banner/chirag-got award7.jpg";
// import fram8 from "../../../assets/images/banner/chirag-got award8.jpg";

import Slider from "react-slick";

function Banner() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <div className="text-white  px-35">
        <div className="slider-container">
          <div className="banner_fram_box">
            <Slider {...settings}>
              <div className="banner_fram_img_box">
                <div className="banner_fram_content_box"></div>
                <img src={fram1} alt="" className="fram-img" />
              </div>
              <div>
                <img src={fram2} alt="" className="fram-img" />
              </div>
              <div>
                <img src={fram3} alt="" className="fram-img" />
              </div>

              <div>
                <img src={fram4} alt="" className="fram-img" />
              </div>
              <div>
                <img src={fram5} alt="" className="fram-img" />
              </div>
              <div>
                <img src={fram6} alt="" className="fram-img" />
              </div>
              <div>
                <img src={fram7} alt="" className="fram-img" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
export default Banner;
