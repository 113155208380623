function GoogleMap() {
  return (
    <div classname="google_map_container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.130091117568!2d77.23173227456769!3d28.505733489829076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1a3e6e6844d%3A0xc08d12db9cf702b5!2s677%2F28%2C%20Ashok%20Vatika%2C%20Deoli%20Gaon%20Nai%20Basti%2C%20Khanpur%2C%20New%20Delhi%2C%20Delhi%20110062!5e0!3m2!1sen!2sin!4v1732351906938!5m2!1sen!2sin"
        width={"100%"}
        height={450}
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
}
export default GoogleMap;
