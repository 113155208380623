import { Link } from "react-router-dom";
import Gallery from "../../components/gallery/Gallery";
import CommonBanner from "../../components/common/commonBannner/CommonBanner";
import commonBannerImg from "../../assets/images/photoGallery/portfolio-image-15.jpg";

function GalleryPage() {
  const commonData = {
    commonHeader: "Gallery",
    backLink: "Home",
    ownPage: "Gallery",
    image: commonBannerImg,
  };
  return (
    <>
      <CommonBanner commonData={commonData} />
      <Gallery />
    </>
  );
}
export default GalleryPage;
