import album1 from "../../../assets/images/objectives/album1.jpg";
import album2 from "../../../assets/images/objectives/album2.jpg";
import album3 from "../../../assets/images/objectives/album3.jpg";
import album4 from "../../../assets/images/objectives/album4.jpg";

function Objective() {
  return (
    <>
      <section className="object_sec">
        <div className="objectives_main_container">
          <div className="container">
            <div className="objective_heading_title">
              <h2>Objectives</h2>
              <p>
                To promote literacy, cultural and other social activities by
                awareness programs, adult education classes, Sarv Shiksha
                Abhiyan, lecture, essay competitions, exhibitions symposium,
                cultural programs, press conferences and seminars.
              </p>
            </div>
            <div className="objectives_gallery_container">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="content_album_box">
                    <div className="album_box">
                      <img src={album1} alt="" className="album_img" />
                    </div>
                    <div className="img_details_box">
                      <h4>Our Basic Principles For Women’s Empowerment</h4>
                      <ul className="album_details_list">
                        <li>
                          Establish high-level corporate leadership for gender
                          equality.
                        </li>
                        <li>
                          Treat all women and men fairly at work – respect and
                          support human rights and nondiscrimination.
                        </li>
                        <li>
                          Ensure the health, safety and well-being of all women
                          and men workers.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="content_album_box">
                    <div className="album_box">
                      <img src={album2} alt="" className="album_img" />
                    </div>
                    <div className="img_details_box">
                      <h4>Our Basic Principles For Women’s Empowerment</h4>
                      <ul className="album_details_list">
                        <li>
                          Establish high-level corporate leadership for gender
                          equality.
                        </li>
                        <li>
                          Treat all women and men fairly at work – respect and
                          support human rights and nondiscrimination.
                        </li>
                        <li>
                          Ensure the health, safety and well-being of all women
                          and men workers.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mt-5">
                  <div className="content_album_box">
                    <div className="album_box">
                      <img src={album3} alt="" className="album_img" />
                    </div>
                    <div className="img_details_box">
                      <h4>Our Basic Principles For Women’s Empowerment</h4>
                      <ul className="album_details_list">
                        <li>
                          Establish high-level corporate leadership for gender
                          equality.
                        </li>
                        <li>
                          Treat all women and men fairly at work – respect and
                          support human rights and nondiscrimination.
                        </li>
                        <li>
                          Ensure the health, safety and well-being of all women
                          and men workers.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mt-5">
                  <div className="content_album_box">
                    <div className="album_box">
                      <img src={album4} alt="" className="album_img" />
                    </div>
                    <div className="img_details_box">
                      <h4>Our Basic Principles For Women’s Empowerment</h4>
                      <ul className="album_details_list">
                        <li>
                          Establish high-level corporate leadership for gender
                          equality.
                        </li>
                        <li>
                          Treat all women and men fairly at work – respect and
                          support human rights and nondiscrimination.
                        </li>
                        <li>
                          Ensure the health, safety and well-being of all women
                          and men workers.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Objective;
