import "./Blog.css";

import BlogBanner from "./blogBanner/BlogBanner";

function Blog() {
  return (
    <>
      <section className="blog_sec">
        <div className="container">
          <div className="blog_header">
            <h2>Blog</h2>
          </div>
          <div className="blog_banner_container">
            <BlogBanner />
          </div>
        </div>
      </section>
    </>
  );
}
export default Blog;
