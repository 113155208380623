// import React, { Component } from "react";
import { FaUserAlt } from "react-icons/fa";
import blogFram1 from "../../../assets/images/blog/blog-fram1.jpg";
import blogFram2 from "../../../assets/images/blog/blog-fram-1.jpg";
import blogFram3 from "../../../assets/images/blog/blog-fram2.png";
import blogFram4 from "../../../assets/images/blog/blog-fram4.jpg";

import Slider from "react-slick";

function BlogBanner() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <Slider {...settings}>
            <div className="col-lg-4 col-md-6">
              <div className="blog_content_box">
                <div className="blog_img_box">
                  <img src={blogFram1} alt="" className="img-fluid" />
                </div>
                <div className="post_meta">
                  <div class=" post-date">
                    
                      <h6 class="top">10 May</h6>

                  </div>

                  <div class="post-meta-content">
                    <span class="post-author">
                      <span class="left">
                        <FaUserAlt className="fa" />
                      </span>
                      <span class="right">
                        <a href="">admin</a>
                      </span>
                      <span class="slash">/</span>
                      <span class="right">
                        <a href=""> 0 Comments</a>{" "}
                      </span>
                    </span>
                  </div>

                  <div className="blog_post_title">
                    <h5>
                      <a href="">Slum Area (State Delhi)</a>
                    </h5>
                  </div>

                  <div className="watch_btn">
                    <a href="" className="read_btn">
                      Continue Reading
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="blog_content_box">
                <div className="blog_img_box">
                  <img src={blogFram2} alt="" className="img-fluid" />
                </div>
                <div className="post_meta">
                  <div class=" post-date">
                    
                      <h6 class="top">10 May</h6>

                  </div>

                  <div class="post-meta-content">
                    <span class="post-author">
                      <span class="left">
                        <FaUserAlt className="fa" />
                      </span>
                      <span class="right">
                        <a href="">admin</a>
                      </span>
                      <span class="slash">/</span>
                      <span class="right">
                        <a href=""> 0 Comments</a>{" "}
                      </span>
                    </span>
                  </div>

                  <div className="blog_post_title">
                    <h5>
                      <a href="">Slum Area (State Delhi)</a>
                    </h5>
                  </div>

                  <div className="watch_btn">
                    <a href="" className="read_btn">
                      Continue Reading
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="blog_content_box">
                <div className="blog_img_box">
                  <img src={blogFram3} alt="" className="img-fluid" />
                </div>
                <div className="post_meta">
                  <div class=" post-date">
                    
                      <h6 class="top">10 May</h6>

                  </div>

                  <div class="post-meta-content">
                    <span class="post-author">
                      <span class="left">
                        <FaUserAlt className="fa" />
                      </span>
                      <span class="right">
                        <a href="">admin</a>
                      </span>
                      <span class="slash">/</span>
                      <span class="right">
                        <a href=""> 0 Comments</a>{" "}
                      </span>
                    </span>
                  </div>

                  <div className="blog_post_title">
                    <h5>
                      <a href="">Slum Area (State Delhi)</a>
                    </h5>
                  </div>

                  <div className="watch_btn">
                    <a href="" className="read_btn">
                      Continue Reading
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="blog_content_box">
                <div className="blog_img_box">
                  <img src={blogFram4} alt="" className="img-fluid" />
                </div>
                <div className="post_meta">
                  <div class=" post-date">
                    
                      <h6 class="top">10 May</h6>

                  </div>

                  <div class="post-meta-content">
                    <span class="post-author">
                      <span class="left">
                        <FaUserAlt className="fa" />
                      </span>
                      <span class="right">
                        <a href="">admin</a>
                      </span>
                      <span class="slash">/</span>
                      <span class="right">
                        <a href=""> 0 Comments</a>{" "}
                      </span>
                    </span>
                  </div>

                  <div className="blog_post_title">
                    <h5>
                      <a href="">Slum Area (State Delhi)</a>
                    </h5>
                  </div>

                  <div className="watch_btn">
                    <a href="" className="read_btn">
                      Continue Reading
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog_content_box">
                <div className="blog_img_box">
                  <img src={blogFram3} alt="" className="img-fluid" />
                </div>
                <div className="post_meta">
                  <div class=" post-date">
                    
                      <h6 class="top">10 May</h6>

                  </div>

                  <div class="post-meta-content">
                    <span class="post-author">
                      <span class="left">
                        <FaUserAlt className="fa" />
                      </span>
                      <span class="right">
                        <a href="">admin</a>
                      </span>
                      <span class="slash">/</span>
                      <span class="right">
                        <a href=""> 0 Comments</a>{" "}
                      </span>
                    </span>
                  </div>

                  <div className="blog_post_title">
                    <h5>
                      <a href="">Slum Area (State Delhi)</a>
                    </h5>
                  </div>

                  <div className="watch_btn">
                    <a href="" className="read_btn">
                      Continue Reading
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}
export default BlogBanner;
