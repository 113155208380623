function TermsCondition() {
  return (
    <div className="condition_container">
      <div className="container">
        <p className="conditions_details">
          Access to and use of this site ( ) is provided by Education & Economic
          Development Society subject to the terms set out below.
          <br />
          Please read through the whole of these terms of use, and our privacy
          policy, before using this website. Accessing any part of this website
          constitutes your agreement to be bound by these terms of use and
          acceptance of our privacy policy regardless of whether or not you
          choose to register with us for the purposes of carrying out any
          transactions. Please note paragraph 1.3 below since it allows us to
          amend these terms of use and our privacy policy at any time without
          prior notice.
          <br />
          Certain provisions of these terms of use may be superseded by
          expressly designated legal notices or terms located on particular
          pages of this web site.
          <br />
          If you do not accept these terms of use, do not use this website.
          <br />
          Those users who register with us to use one or more of the
          transactional areas of our website have particular responsibilities
          and their specific attention is drawn to paragraph 6 of these terms of
          use.
          <br /> Similarly, those charities that register with us to upload or
          otherwise provide information about their charity should note in
          particular the provisions of paragraphs 2.5 and 2.6.
        </p>
        <div className="list_box_terms mt-5">
          <h4>Introduction</h4>
          <ul>
            <li>Data and information: access and download licence</li>
            <li>Availability of access</li>
            <li>Website material and user conduct</li>
            <li>Links to and from other websites</li>
            <li>Responsibilities of transactional users</li>
            <li>Sanctions for breach of these terms of use</li>
            <li>Exclusion of liability</li>
            <li>Entire agreement</li>
            <li>Governing law and jurisdiction</li>
            <li>Cancellation and refund policy</li>
          </ul>
        </div>
        <div className="list_box_terms mt-5">
          <h4>1.Introduction</h4>
          {/* <ul>
            <li>Data and information: access and download licence</li>
            <li>Availability of access</li>
            <li>Website material and user conduct</li>
            <li>Links to and from other websites</li>
            <li>Responsibilities of transactional users</li>
            <li>Sanctions for breach of these terms of use</li>
            <li>Exclusion of liability</li>
            <li>Entire agreement</li>
            <li>Governing law and jurisdiction</li>
            <li>Cancellation and refund policy</li>
          </ul> */}
          <p className="conditions_details">
            1.1 You may access most areas of this website without registering
            your details with us.. Any personal information you supply to us
            when you use our website will be used in accordance with our privacy
            policy.
          </p>
          <p className="conditions_details">
            1.2 By accessing any part of this website, you shall be deemed to
            have accepted these terms of use and our privacy policy in full. If
            you do not accept them in full, you must leave this website
            immediately.
          </p>
          <p className="conditions_details">
            1.3 We may make improvements or changes to the information and
            services on our website, or terminate our website, at any time
            without prior notice. We may also modify these terms of use and our
            privacy policy at any time without prior notice, and every such
            modification will be effective immediately upon posting of the
            modified terms of use and/or the modified privacy policy on our
            website. Your continued use of our website is deemed to be your
            acceptance of the modified terms of use and/or the modified privacy
            policy. You should, therefore, review this page regularly to ensure
            that you are aware of all improvements, changes and modifications
            made.
          </p>
          <p>2. Data and information: access and download licence</p>
          <p className="conditions_details">
            2.1 Unless otherwise stated, the data and information you can access
            on this website has been developed and compiled by Education &
            Economic Development Society or one of its licensors and as such is
            protected by copyright, database and other laws concerning
            intellectual property. All rights in relation to such data and
            information (as well as in relation to the organisation and layout
            of our website and the underlying software code) are reserved by
            Education & Economic Development Society and its licensors.
          </p>
          <p className="conditions_details">
            2.2 You may access, download and print the data and information and
            retain that data and information as a data source for yourself. You
            may use the information and data only for your own informational
            purposes and, provided that in each case you comply with the
            associated conditions that make use for such purposes lawful, for
            purposes that constitute “fair dealing” within the meaning of the
            Copyright, Designs and Patents Act 1988 and for those purposes which
            that Act and/or the Copyright and Rights in Databases Regulations
            1997 declare not to constitute infringement of the intellectual
            property rights with which they deal.
          </p>
          <p className="conditions_details">
            2.3 The download licence in paragraph 2.2 is personal, non-exclusive
            and non-transferable and is given on the following basis:
          </p>

          <ul className="list_pragraph">
            <li>
              no documents or related graphics on this web site are modified in
              any way;
            </li>
            <li>
              no graphics on this website are used separately from accompanying
              text; and
            </li>
            <li>
              copyright and trade mark notices and this permission notice appear
              in all copies.
            </li>
          </ul>
          <p className="conditions_details">
            2.4 Except as provided in paragraphs 2.2 and 2.3 you may not
            otherwise access, download, copy or use any of the data or
            information on this website, and under no circumstances may you sell
            it or otherwise seek to turn it to account, directly or indirectly.
            Similarly, except as provided in those paragraphs, no part of this
            website may be reproduced or stored in any other website or included
            in any public or private electronic retrieval system or service
            without Education & Economic Development Society’s prior written
            permission or that of the relevant licensors.
          </p>

          <p className="conditions_details">
            2.5 The data and information is provided by Education & Economic
            Development Society and its licensors at no cost to users of this
            site on an “as is” basis, to provide information about Education &
            Economic Development Society, philanthropy generally, methods of
            charitable giving, the charities which have supplied information
            about themselves for this site and to facilitate the building of
            relationships between donors and charities. Education & Economic
            Development Society and its licensors may make changes to such data
            and information at any time without notice Given the basis on which
            the data and information is being provided:
          </p>
          <p className="conditions_details">
            (a) no commitment is given to update the data and information on
            this website nor is any assurance given that it is accurate,
            complete or up-to-date; and
          </p>
          <p className="conditions_details">
            (b) to the maximum extent permitted by law, Education & Economic
            Development Society provides you with this web site on the basis
            that Education & Economic Development Society excludes all
            representations, warranties, conditions and other terms (including,
            without limitation, the conditions implied by law of satisfactory
            quality, fitness for purpose and the use of reasonable care and
            skill) which but for this provision might have effect in relation to
            this website.
          </p>
          <p className="conditions_details">
            2.6 Users of this site should also note that the inclusion on this
            site of data or information about charities or other third parties,
            or links to their web sites, does not in any way constitute an
            endorsement by Education & Economic Development Society of any such
            charity or third party, whether as to its financial condition or
            prospects, its charitable or other status, its continuance in good
            standing or its aims and objectives. Education & Economic
            Development Society does not verify any of the data and information
            made available by such charities and third parties either on this
            site or on their own websites, the responsibility for that data and
            information remaining with those charities and third parties.
          </p>
          <p>3. Availability of access</p>
          <p className="conditions_details">
            3.1 While we endeavour to ensure that this website is normally
            available 24 hours a day, we shall not be liable if for any reason
            it is unavailable at any time or for any period.
          </p>
          <p className="conditions_details">
            3.2 Access to this website may be suspended temporarily and without
            notice in the case of system failure, maintenance, upgrade or repair
            or for reasons beyond Education & Economic Development Society ‘s
            control.
          </p>
          <p className="conditions_details">
            4. Website material and user conduct
          </p>
          <p className="conditions_details">
            4.1 Other than personally identifiable information, which is covered
            under our privacy policy, any material you transmit or post to this
            website shall be considered non-confidential and non-proprietary.
            Education & Economic Development Society shall have no obligations
            with respect to such material. Education & Economic Development
            Society shall be free to edit, copy, disclose, distribute,
            incorporate and otherwise use such material and all data, images,
            sounds, text and other things embodied therein for any and all
            commercial or non-commercial purposes.
          </p>
          <p className="conditions_details">
            4.2 You are prohibited from posting or transmitting to or from this
            website any material:
          </p>
          <p className="conditions_details">
            (a) that is threatening, defamatory, obscene, indecent, seditious,
            offensive, pornographic, abusive, liable to incite racial hatred,
            discriminatory, menacing, scandalous, inflammatory, blasphemous, in
            breach of confidence, in breach of privacy, misleading, fraudulent,
            unlawful, constitute a contempt of court or otherwise be prejudicial
            to the administration of civil or criminal justice; or
          </p>
          <p className="conditions_details">
            (b) that is in breach of any third party’s intellectual or other
            property rights or interests;
          </p>
          <p className="conditions_details">
            (c) which may cause annoyance or inconvenience; or
          </p>
          <p className="conditions_details">
            (d) for which you have not obtained all necessary licences and/or
            approvals; or
          </p>
          <p className="conditions_details">
            (e) which constitutes or encourages conduct that would be considered
            a criminal offence, give rise to civil liability, or otherwise be
            contrary to the law of or infringe the rights of any third party, in
            the UK or any other country in the world; or
          </p>
          <p className="conditions_details">
            (f) which is technically harmful (including, without limitation,
            computer viruses, logic bombs, Trojan horses, worms, harmful
            components, corrupted data or other malicious software or harmful
            data).
          </p>
          <p className="conditions_details">
            4.3 You may not misuse the website (including, without limitation,
            by hacking) and may only use the website for lawful and proper
            purposes, in compliance with all applicable laws, regulations and
            codes of practice and otherwise in accordance with the other
            provisions of these terms of use.
          </p>
          <p className="conditions_details">
            4.4 Without prejudice to paragraphs 4.2 and 4.3, by accessing our
            web site you undertake that you will not:
          </p>
          <p className="conditions_details">
            (a) use our website in a manner which causes or may cause any
            infringement of the rights of any other;
          </p>
          <p className="conditions_details">
            (b) use any software, routine or device to interfere or attempt to
            interfere electronically or manually with the operation or
            functionality of our website including but not limited to uploading
            or making available files containing corrupt data or viruses via
            whatever means;
          </p>
          <p className="conditions_details">
            (c) deface, alter or interfere with the front end ‘look and feel’ of
            our website or the underlying software code; or
          </p>
          <p className="conditions_details">
            (d) take any action that imposes an unreasonable or
            disproportionately large load on our website or related
            infrastructure.
          </p>
          <p className="conditions_details">
            4.5 Education & Economic Development Society shall fully co-operate
            with any law enforcement authorities or court order requesting or
            directing Education & Economic Development Society to disclose the
            identity or locate anyone posting any material in breach of any of
            paragraphs 4.2 to 4.4 inclusive.
          </p>
          <p className="conditions_details">
            5. Links to and from other web sites
          </p>
          <p className="conditions_details">
            5.1 Links to third party websites on this website are provided
            solely for your convenience. If you use these links, you leave this
            web site. Education & Economic Development Society has not reviewed
            these third party websites and does not control and is not
            responsible for those websites, their content or availability, and
            in particular Chirag Foundation is not responsible for any
            information, goods or services contained, offered, described or made
            available on, or accessible through, those websites or by any means
            referred to on those sites. Education & Economic Development Society
            therefore does not endorse or make any representations about those
            websites, or any material found there, or any results that may be
            obtained from using them. If you decide to access any of the third
            party websites linked or accessible through those that are linked to
            this website, you do so entirely at your own risk.
          </p>
          <p className="conditions_details">
            5.2 If you would like to link to or otherwise make use of any part
            of this website, you may only do so subject to the following
            conditions unless in any particular case Education & Economic
            Development Society ‘s express prior written permission is obtained.
          </p>
          <p className="conditions_details">
            (a) you do not remove, distort or otherwise alter the size or
            appearance of any graphics or logos;
          </p>
          <p className="conditions_details">
            (b) you do not create a frame or any other browser or border
            environment around this website;
          </p>
          <p className="conditions_details">
            (c) you do not in any way imply that Education & Economic
            Development Society is endorsing any products or services other than
            its own;
          </p>
          <p className="conditions_details">
            (d) you do not misrepresent your relationship with Education &
            Economic Development Society nor present any other false information
            about the Education & Economic Development Society;
          </p>
          <p className="conditions_details">
            (e) you do not otherwise use any trade or service marks displayed on
            this web site without prior express written permission from Chirag
            Foundation;
          </p>
          <p>
            (f) you do not link from a web site that is not owned by you; and
          </p>
          <p>
            (g) your web site does not contain the sort of content that is
            described in paragraph
          </p>
          <p>
            5.3 (a) – (e) inclusive above, infringes any intellectual property
            rights or other rights of any other person or otherwise does not
            comply with all applicable laws and regulations.
          </p>
          <p>
            If you wish to link to or otherwise make use of any part of this
            website on any other basis, or you are unsure whether your proposed
            linking arrangements will contravene these terms of use please
            contact Education & Economic Development Society.
          </p>
          <p className="conditions_details">
            5.4 Education & Economic Development Society expressly reserves the
            right to revoke the right granted in paragraph 5.2 if at any time
            Education & Economic Development Society in its absolute discretion
            considers a link is:
          </p>
          <p className="conditions_details">
            (a) Inappropriate or controversial; or
          </p>
          <p className="conditions_details">
            (b) for breach of these terms and in either case Education &
            Economic Development Society may take any action it deems
            appropriate.
          </p>
          <p>6. Responsibilities of transactional users</p>
          <p className="conditions_details">
            6.1 Users who have registered to undertake transactions on one or
            more areas of our website must at all times safeguard their personal
            login details (e.g. user id, passwords, login and access codes and
            PIN numbers). For example, such users should ensure that third
            parties do not overlook them when logging on to transact any
            business. Such users must not disclose their login details to any
            other person nor with multiple users on a network except as may be
            expressly permitted pursuant to the terms of use applicable to the
            transactions for which the user has registered.
          </p>
          <p>
            Responsibility for the security of all your login details rests with
            you
          </p>
          <p className="conditions_details">
            6.2 If you are registered as described in paragraph 6.1, it is
            important you understand that you are responsible for the accuracy
            and genuineness of all instructions and information sent to us, from
            login to log-off. This means that you must ensure that all
            instructions and information are carefully checked before being sent
            to us. It is also important that you read the information provided
            on the transactional web pages that you are registered to use. That
            information explains the fees and charges payable for the
            transactions concerned, any deductions that will or may be applied
            and the circumstances in which funds you send may not be applied as
            you have instructed.
          </p>
          <p className="conditions_details">
            6.3 This site is as secure we can make it by using encryption
            technology and adopting security and anti-virus practices routinely
            used and adopted as a matter of good practice by other financial
            services businesses in the UK. Nevertheless, it is important you
            understand that every internet site, every computer that connects to
            the internet and all internet communication is susceptible to attack
            by computer hackers and the ever-increasing variety and
            sophistication of computer viruses. Education & Economic Development
            Society will do all it reasonably can to protect this site and all
            internet communications passing between Chirag m Foundation and
            users, but it cannot be held responsible for any losses fairly
            attributable to a user’s failure to take its own reasonable
            precautions to prevent interception of or interference with any such
            communications (including, without limitation) failure to use and
            keep up to date firewalls and anti-virus software on the user’s own
            computers or computer systems.
          </p>
          <p>7. Sanctions for breach of these terms of use</p>
          <p className="conditions_details">
            7.1 Where we believe, in our absolute discretion, that you are in
            breach of any of these terms and conditions we will be entitled
            without prior notice to deny you further use of our website and/or
            to remove from our website all information you have made available
            on or through our website. If we take either or both of these
            courses of action our doing so will in all instances be without
            prejudice to any of our other rights, at law or otherwise.
          </p>
          <p className="conditions_details">
            7.2 You agree to indemnify and keep us indemnified from and against
            any and all actions, proceedings, costs, claims, damages, losses and
            expenses of whatsoever nature and howsoever arising as a result of
            or in connection with any breach by you of these terms and
            conditions, including any failure to indemnify us as required by
            this provision.
          </p>
          <p className="conditions_details">8. Exclusion of liability</p>
          <p className="conditions_details">
            8.1 Education & Economic Development Society, its licensors (whether
            or not involved in creating, producing, maintaining or delivering
            this website), and the trustees, officers, directors, employees,
            contractors, shareholders or agents of each of them, exclude all
            liability and responsibility for any amount or kind of loss or
            damage that may result to you or a third party (including without
            limitation, any direct, indirect, punitive or consequential loss or
            damages, or any loss of income, profits, goodwill, data, contracts,
            use of money, or loss or damages arising from or connected in any
            way to business interruption, and whether in tort (including without
            limitation negligence), contract or otherwise) in connection with
            this website in any way.
          </p>
          <p className="conditions_details">
            8.2 Or in connection with the use, inability to use or the results
            of use of this website, any websites linked to this website or the
            material on or accessible through such websites, including but not
            limited to loss or damage due to viruses that may infect your
            computer equipment, software, data or other property on account of
            your access to, use of, or browsing this website or your downloading
            of any material from this website or from any websites linked to
            this website or from any websites accessible through those that are
            linked to this website.
          </p>
          <p className="conditions_details">
            8.3 Nothing in these terms of use shall exclude or limit Chirag
            Foundation’s liability f
          </p>
          <p className="conditions_details">
            (a) death or personal injury caused by negligence (as such term is
            defined by the Unfair Contract Terms Act 1977); or
          </p>
          <p className="conditions_details">(b) fraud; or</p>
          <p className="conditions_details">
            (c) any liability which cannot be excluded or limited under
            applicable law.
          </p>
          <p className="conditions_details">
            8.4 If your use of material on this website results in the need for
            servicing, repair or correction of equipment, software or data, you
            assume all costs thereof.
          </p>
          <p>9. Entire agreement</p>
          <p className="conditions_details">
            These terms of use, together with our terms of use for credit and
            debit card donations, Charity Search, Education & Economic
            Development Society Charity account, Education & Economic
            Development Society Company account and Education & Economic
            Development Society Donate contain all the terms of your agreement
            with us relating to your use of our website. No other written or
            oral statement, whenever made (including statements in any brochure
            or promotional literature or materials published by us), will be
            incorporated.
          </p>
          <p>10. Governing law and jurisdiction</p>
          <p className="conditions_details">
            Your use of our website, any information downloaded from it and the
            operation of these terms and conditions will be governed by and
            construed in accordance with the laws of England and Wales and you
            agree to submit to the exclusive jurisdiction of the English Courts
            in relation to any action, proceeding, claim, dispute or difference
            arising out of your use of our website or otherwise in connection
            with our website.
          </p>
          <p>11. Cancellation and refund policy</p>
          <p className="conditions_details">
            Donations processed successfully through the payment gateway will
            not be refunded. Please choose the cause you wish to support and the
            amount you wish to contribute carefully. No amount will be deducted
            in case the transaction fails to process. No donation is small when
            it comes to helping the disadvantaged section of our society. Let us
            get on with giving!
          </p>
        </div>
      </div>
    </div>
  );
}
export default TermsCondition;
