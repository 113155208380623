import Blog from "../../components/blog/Blog";

function BlogPage() {
  return (
    <>
      <Blog />
    </>
  );
}
export default BlogPage;
