import Blog from "../blog/Blog";
import Banner from "./banner/Banner";
import CancerCharity from "./cancerCharity/CancerCharity";
import CancerNgo from "./cancerNgo/CancerNgo";
import Objective from "./objectives/Objective";

function Home() {
  return (
    <>
      <Banner />
      <CancerCharity />
      <CancerNgo />
      <Objective />
      <Blog />
    </>
  );
}
export default Home;
