import TopHeader from "./topHeader/TopHeader";
import UperHeader from "./uperHeader/UperHeader";

function Header() {
  return (
    <div className="main_header">
      <TopHeader />
      <UperHeader />
    </div>
  );
}
export default Header;
