import React from 'react'
import PhonePayPaymentComp from '../../components/phonePayPaymentFormComp/PhonePayPaymentComp'

function PhonePayPaymentFormPage() {
    return (
        <>
            <PhonePayPaymentComp />
        </>
    )
}

export default PhonePayPaymentFormPage