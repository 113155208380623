import aboutfram1 from "../../assets/images/aboutus/IMG-20210329-WA0032.jpg";
import aboutfram2 from "../../assets/images/aboutus/IMG_20220510_161930_1-scaled.jpg";
import "./AboutUs.css";
import Mission from "./mission/Mission";
import Vision from "./vision/Vision";
function AboutUs() {
  return (
    <>
      <section className="about_us_main">
        <div className="about_container">
          <div className="row">
            <div className="col">
              <div className="details_container">
                <div className="chirag_found_title">
                  <h1 className="about_heading">
                    Welcome To Education & Economic Development Society
                  </h1>
                  <p className="about_head_sub_details">
                  Welcome To Education And Economic Development Society
                  Education And Economic Development Society is a non-governmental organization (NGO). The registration number of the organization is: s/46301?(01-07-2003). It was established in the year 2003. Education And Economic Development Society works in the area of Advocacy and Research, Aged and elderly, Children, Disability, Education & Literacy, Environment and natural resource management, Health & Nutrition, HIV/AIDS, Labour & Employment, Legal Awareness & Aid, Micro Finance (SHGs), Micro Small & Medium Enterprises, Panchayati Raj, Right to Information & Advocacy, Rural Development & Poverty Alleviation, Urban Development & Poverty Alleviation, Vocational Training, Womens Development & Empowerment, Youth Affairs, etc. The organization works towards the promotion of sustainable development. The organization operates in Delhi, India.
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="about_sub_img_container">
                <div className="row">
                  <div className="col">
                    <div className="about_sub_img">
                      <img src={aboutfram2} alt="" className="about_sub_img1" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="about_sub_img">
                      <img src={aboutfram1} alt="" className="about_sub_img2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Mission />
      {/* <Vision /> */}
    </>
  );
}
export default AboutUs;
