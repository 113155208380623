import { Link } from "react-router-dom";
import AboutUs from "../../components/aboutUs/AboutUs";
import CommonBanner from "../../components/common/commonBannner/CommonBanner";
import aboutBanner from "../../assets/images/commonBanner/about-cover.png"

function AboutUsPage() {
  const commonData = {
    commonHeader: "About Us",
    backLink: "Home",
    ownPage: "About Us",
    image: aboutBanner,
  };
  return (
    <>
      {/* <section className="page_tittle">
        <div className="common_banner_container">
          <div className="content_box">
            <div className="content_wrapper">
              <div className="title">
                <h1>About Us</h1>
              </div>
              <ul className="bread_crumb clearfix">
                <li className="breadcrumb_item">
                  <Link to="/home">Home &nbsp;</Link>
                </li>
                <li className="breadcrumb_item">|</li>
                <li className="breadcrumb_item">About Us</li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}

      <CommonBanner commonData={commonData} />
      <AboutUs />
    </>
  );
}
export default AboutUsPage;
