import ReactPlayer from "react-player";
import photogallery1 from "../../assets/images/photoGallery/portfolio-image-1.jpg";
import photogallery2 from "../../assets/images/photoGallery/portfolio-image-2.jpg";
import photogallery3 from "../../assets/images/photoGallery/portfolio-image-3.jpg";
import photogallery4 from "../../assets/images/photoGallery/portfolio-image-13.jpg";
import photogallery5 from "../../assets/images/photoGallery/portfolio-image-14.jpg";
import photogallery6 from "../../assets/images/photoGallery/portfolio-image-15.jpg";

function Gallery() {
  return (
    <>
      <section className="mt-5 mb-5">
        <div className="gallery_container">
          <div className="container mb-5">
            <div className="row mt-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <div className="photo_gallery_sec">
                  <div>
                    <div className="photogallery_image_box">
                      <a href="" className="edgtf-portfolio-link">
                        <img
                          src={photogallery1}
                          alt=""
                          className="photogallery_img"
                        />
                        <div className="img_hover_box"></div>
                      </a>
                    </div>

                    <div className="explain_img_content">
                      <h4>Medical Treatment</h4>
                      <h6>Kids Care</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="photo_gallery_sec">
                  <div>
                    <div className="photogallery_image_box">
                      <a href="" className="edgtf-portfolio-link">
                        <img
                          src={photogallery2}
                          alt=""
                          className="photogallery_img"
                        />
                        <div className="img_hover_box"></div>
                      </a>
                    </div>

                    <div className="explain_img_content">
                      <h4>Food For All</h4>
                      <h6>Kids Care</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="photo_gallery_sec">
                  <div>
                    <div className="photogallery_image_box">
                      <a href="" className="edgtf-portfolio-link">
                        <img
                          src={photogallery3}
                          alt=""
                          className="photogallery_img"
                        />
                        <div className="img_hover_box"></div>
                      </a>
                    </div>

                    <div className="explain_img_content">
                      <h4>Education For All</h4>
                      <h6>Kids Care</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="photo_gallery_sec">
                  <div>
                    <div className="photogallery_image_box">
                      <a href="" className="edgtf-portfolio-link">
                        <img
                          src={photogallery4}
                          alt=""
                          className="photogallery_img"
                        />
                        <div className="img_hover_box"></div>
                      </a>
                    </div>

                    <div className="explain_img_content">
                      <h4>h Helping Kids and Youth</h4>
                      <h6>Kids Care</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="photo_gallery_sec">
                  <div>
                    <div className="photogallery_image_box">
                      <a href="" className="edgtf-portfolio-link">
                        <img
                          src={photogallery5}
                          alt=""
                          className="photogallery_img"
                        />
                        <div className="img_hover_box"></div>
                      </a>
                    </div>

                    <div className="explain_img_content">
                      <h4>j Building Trust</h4>
                      <h6>Kids Care</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="photo_gallery_sec">
                  <div>
                    <div className="photogallery_image_box">
                      <a href="" className="edgtf-portfolio-link">
                        <img
                          src={photogallery6}
                          alt=""
                          className="photogallery_img"
                        />
                        <div className="img_hover_box"></div>
                      </a>
                    </div>

                    <div className="explain_img_content">
                      <h4>Huge Investments</h4>
                      <h6>Kids Care</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Gallery;
