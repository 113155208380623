import { Link } from "react-router-dom";
import charityAward from "../../../assets/images/cancer-charity-award/award.jpg";

function CancerCharity() {
  return (
    <>
      <section className="caner_charity_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="round_award_sec">
                <img
                  src={charityAward}
                  alt=""
                  className="img_sec_charity_award"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="charity_award_text_sec">
                <h3 className="charity_award_text_header">
                  Welcome To Education And Economic Development Society
                </h3>
                <p className="charity_award_text_details">
                  Education And Economic Development Society is a
                  non-governmental organization (NGO). The registration number
                  of the organization is: s/46301?(01-07-2003). It was
                  established in the year 2003. Education And Economic
                  Development Society works in the area of Advocacy and
                  Research, Aged and elderly, Children, Disability, Education &
                  Literacy, Environment and natural resource management, Health
                  & Nutrition, HIV/AIDS, Labour & Employment, Legal Awareness &
                  Aid, Micro Finance (SHGs), Micro Small & Medium Enterprises,
                  Panchayati Raj, Right to Information & Advocacy, Rural
                  Development & Poverty Alleviation, Urban Development & Poverty
                  Alleviation...
                </p>
                <Link to="/aboutUs" className="charity_read_more_btn">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default CancerCharity;
