import missionimg from "../../../assets/images/aboutus/mission.jpg";
import visionimg from "../../../assets/images/aboutus/chirag-got award5.jpg";

import "./Mission.css";
function Mission() {
  return (
    <>
      <div className="mission_main_wrap">
        <div className="container ">
          <div className="row">
            <div className="col-lg-6">
              <div className="mission_img_box">
                <img src={missionimg} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mission_details_container">
                <div className="mission_heading">
                  <h1 className="miss_heading">Purpose </h1>
                  <p className="miss_sub_details ">
                    To create a safe and enabling environment where disabled
                    people lead healthy life.
                  </p>
                </div>
              </div>
              <div className="mt-4 p-3">
                <div className="mission_img_box mb-5">
                  <img src={visionimg} alt="" className="vision_img" />
                </div>
                <div className="mission_details_container ">
                  <div className="mission_heading">
                    <h1 className="miss_heading">Aims/Objectives/Mission </h1>
                    <p className="miss_sub_details">
                      Our mission is to enable the underprivileged and disabled
                      people to live independent and dignified life.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Mission;
